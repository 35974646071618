<template>
  <v-card class="mb-7" elevation="0" outlined tile>
    <v-card-text class="pa-4">
      <div v-if="elementVisible">
        <vue-apex-charts type="donut" height="400" :options="chartOptions" :series="series"></vue-apex-charts>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'CumplimientoXCompromiso',
  props: {
    datos: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    series: [],
    chartOptions: {
      labels: ['NO', 'POR VENCER', 'SI'],
      chart: {
        type: 'donut',
        toolbar: {
          show: true
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function(val) {
          let valor = parseFloat(val).toFixed(2);
          return valor + '%';
        }
      },
      title: {
        text: '% DE CUMPLIMIENTO POR COMPROMISOS',
        align: 'left',
        margin: 1,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '16px',
          fontWeight: 'normal',
          fontFamily: 'Roboto, Helvetica, Arial',
          color: 'rgba(0, 0, 0, 0.8)'
        }
      },
      legend: {
        show: true,
        position: 'top',
        fontSize: '14px',
        fontFamily: 'Roboto, Helvetica, Arial',
        markers: {
          width: 14,
          height: 14,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
        },
        labels: {
          colors: '#424242',
          useSeriesColors: false
        },
        itemMargin: {
          horizontal: 15,
          vertical: 5
        },
        onItemHover: {
          highlightDataSeries: true
        },
        onItemClick: {
          toggleDataSeries: true
        }
      },
      grid: {
        padding: {
          left: 0,
          right: 0
        },
        borderColor: 'rgba(0,0,0,0.5)'
      },
      plotOptions: {
        pie: {
          customScale: 1.0,
          donut: {
            size: '65%',
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: '18px',
                color: undefined,
                offsetY: -10
              },
              value: {
                show: true,
                color: '#99abb4',
                formatter: function(w) {
                  return w;
                }
              },
              total: {
                show: true,
                label: 'TOTAL',
                color: '#99abb4',
                formatter: function(w) {
                  let total =
                    w.globals.seriesPercent[0][0] + w.globals.seriesPercent[1][0] + w.globals.seriesPercent[2][0];
                  return total + '%';
                }
              }
            }
          }
        }
      },
      stroke: {
        width: 1,
        colors: 'rgba(0,0,0,0.1)'
      },
      colors: ['#d90429', '#02a99e', '#27A300'],
      tooltip: {
        fillSeriesColor: false,
        y: {
          formatter: function(value) {
            return value + ' COMPROMISOS';
          }
        }
      },
      responsive: []
    },
    elementVisible: false
  }),
  watch: {
    async datos(val) {
      console.log(val);
      let acuerdosi = 0;
      let acuerdono = 0;
      let acuerdopv = 0;
      let s1 = 0;
      let s2 = 0;
      let s3 = 0;
      /* let total = 0; */
      if (val.acuerdossubareas?.length > 0) {
        await val.acuerdossubareas.forEach((element) => {
          acuerdosi += parseInt(element.totalsi);
          acuerdono += parseInt(element.totalno);
          acuerdopv += parseInt(element.totalporvencer);
        });
        /* total = acuerdosi + acuerdono + acuerdopv;
        s1 = parseFloat((acuerdosi * 100) / total).toFixed(2);
        s2 = parseFloat((acuerdono * 100) / total).toFixed(2);
        s3 = parseFloat((acuerdopv * 100) / total).toFixed(2); */
        s1 = acuerdosi;
        s2 = acuerdono;
        s3 = acuerdopv;

        this.series = [s2, s3, s1];
        this.elementVisible = true;
      }
    }
  },
  created() {},
  components: {
    VueApexCharts
  }
};
</script>
